import './App.css';
import ZlatanComponent from './hellomessage';
import { useState } from 'react';

import { useEffect } from 'react';


function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showText, setShowText] = useState(false); 

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    
    const timeout = setTimeout(() => {
      setShowText(true);
    }, 1000); 

    return () => clearTimeout(timeout); 
  }, []); 


  return (
    <>
      <section className='uvodna'>
        <header>
          <div className="navbar">
            <div className="logo"><a href="#">ZlatanDev</a></div>
            <ul className={`links ${menuOpen ? 'active' : ''}`}>
              <li><a href="#projects">Projects</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#testimonials">Testimonials</a></li>
            </ul>
            <a href="mailto:zlatkopsnracun@gmail.com" className="action_btn">Get started</a>
            <div className="toggle_btn" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>

        <div className="name">
          <h2>Hello,</h2>
          <p>I'm Zlatan</p>
        </div>
        <div className='avatar'>
          <img src="avatarnovi.png" alt="Zlatan" />
        </div>
        <main>
          <ZlatanComponent />
        </main>

        <div className={`hometext ${showText ? 'show' : ''}`}>
          <h3>I'm Zlatan, a versatile and dynamic professional with a passion for creating<br />innovative digital solutions. With a diverse skill set that spans full stack development,<br />meta ads specialization, content creation, and UX design,<br />I bring a holistic approach to every project I undertake.</h3>
        </div>
        <a href="mailto:zlatkopsnracun@gmail.com" className='button-1'>Contact me</a>
        
      </section>

      <section id='projects' className='projects'>
        <div className='uvodnitekst'>
          <h1>Recent projects</h1>
          <p>Focused on creating new world of possibilities</p>
        </div>

        <div className='polja'>
          <div className='project-card'>
            <div className='project-image'>
              <img src="slikica2.png" alt="Project 1" />
            </div>
            <div className='project-text'>
              <h1>Access Solutions</h1>
              <p>A responsive, feature-rich portfolio website for a cybersecurity company.</p>
              <a href="https://access.ba/" className="button">Visit website</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="ielts.png" alt="Project 2" />
            </div>
            <div className='project-text'>
              <h1>IELTS Kate</h1>
              <p>Managing Facebook ads account, and delivering new international students<br />every month.</p>
              <a href="https://www.ielts-kate.com/" className="button">Visit website and Facebook page</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="pop.png" alt="Project 3" />
            </div>
            <div className='project-text'>
              <h1>Pop Rock School Sarajevo</h1>
              <p>Managing social media accounts, creating engaging creatives, content creator.</p>
              <a href="https://www.poprockskolasarajevo.com/" className="button">Visit website, Instagram and Facebook page</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="maths.png" alt="Project 4" />
            </div>
            <div className='project-text'>
              <h1>Mathszx Tutoring School London</h1>
              <p>Interactive website, creating engaging creatives, delivering new students<br />via Facebook ads.</p>
              <a href="https://www.mathszxtutoringservices.co.uk/" className="button">Visit website, Instagram and Facebook page</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="osfg.png" alt="Project 5" />
            </div>
            <div className='project-text'>
              <h1>Fatima Gunic High School</h1>
              <p>Creating beautiful creatives, managing portfolio website.</p>
              <a href="https://osfg.edu.ba/" className="button">Visit website</a>
            </div>
          </div>

          <div className='project-card'>
            
            <div className='project-text'>
              <h1>This could be space for your company?</h1>
              <p></p>
              
            </div>
          </div>
        </div>
      </section>

      <section id="about" className='about'>
      <div className="about-me-container">
      <div className="about-me-header">
        <h1>About me</h1>
        <p>Skills that make change</p>
      </div>
      <div className='left'>
        <img src='ai.png'></img>
      </div>
      <div className="about-me-content">
       
        <div className="about-me-text">
          <div className="about-me-box">
            
            <h1 className='prvi'>Skills That Propel Innovation Forward</h1>
            <div class="image-container">
  <div class="formula">
    <img src="f1.png" alt="Formula Image"></img>
  </div>
  <div class="sat">
    <img src="sat.png" alt="Sat Image"></img>
  </div>
</div>
              <p className='drugi'>I am a driven and ambitious leader who constantly strives for more. My self-critical nature ensures that I am always pushing myself to improve and achieve higher standards. Beyond my professional aspirations, I have a keen interest in watches and a passion for Formula 1, which fuel my curiosity and enthusiasm in these areas.</p>
            
          </div>
          <div className="about-me-box">
            <h1 className='treci'>Technologies I love to build with.</h1>
            <div className="slikice">
  <div class="image-wrapper">
    <img src="re.svg" alt="React"></img>
    <p>React</p>
  </div>
  <div class="image-wrapper">
    <img src="ts.svg" alt="TypeScript"></img>
    <p>TypeScript</p>
  </div>
  <div class="image-wrapper">
    <img src="js.svg" alt="JavaScript"></img>
    <p>JavaScript</p>
  </div>
  <div class="image-wrapper">
    <img src="my.svg" alt="MySQL"></img>
    <p>MySQL</p>
  </div>
  <div class="image-wrapper">
    <img src="mango.svg" alt="MongoDB"></img>
    <p>MongoDB</p>
  </div>
  <div class="image-wrapper">
    <img src="nodii.svg" alt="Node.js"></img>
    <p>Node.js</p>
  </div>
</div>

          </div>
          
        </div>
      </div>
    </div>
      </section>

  
<section id="testimonials" className='testimonials'>


<div className="section__container">
      <div className="header">
        <p>TESTIMONIALS</p>
        <h1>What my clients say.</h1>
      </div>
      <div className="testimonials__grid">
        <div className="card">
          <span><i className="ri-double-quotes-l"></i></span>
          <p>
           " I am working with Zlatan for such a long time now, and he is really dedicated to his work."
          </p>
          <hr />
          <img src="slikica2.png" alt="user" />
          <p className="name1">Access Solutions</p>
        </div>
        <div className="card">
          <span><i className="ri-double-quotes-l"></i></span>
          <p>
            "Zlatan really did brought a lot of new students to our school, till the point we had to hire new teachers!"
          </p>
          <hr />
          <img src="pop.png" alt="user" />
          <p className="name1">Pop Rock School Sarajevo</p>
        </div>
        <div className="card">
          <span><i className="ri-double-quotes-l"></i></span>
          <p>
            "He was avaiable 24/7, he even did some of the work he was not even payed for! Zlatan brought us a lot of new students."
          </p>
          <hr />
          <img src="maths.png" alt="user" />
          <p className="name1">MATHSZX Tutoring School London</p>
        </div>
      </div>
      <div className="footer">
        <h4> I want you to be satisfied with my service!</h4>
        <p>
      My elite coding expertise, combined with your unique project needs, allows me to create a software solution specifically tailored to you.
        </p>
        <a href="mailto:zlatkopsnracun@gmail.com" className="button">GET AN OFFER</a>
      </div>
    </div>







</section>

<section className="footeric">
      <div className="social-links">
        <a href="https://www.linkedin.com/in/zlatan-hadzic-7395a7275" target="_blank" rel="noopener noreferrer">
          <img src="linkedin.png" alt="LinkedIn" />
        </a>
        <a href="https://www.instagram.com/zlatanhadzicc/" target="_blank" rel="noopener noreferrer">
          <img src="insta.png" alt="Instagram" />
        </a>
        <a href="https://github.com/ZlatanHadzic" target="_blank" rel="noopener noreferrer">
          <img src="git.png" alt="GitHub" />
        </a>
       
      </div>
      <div className='image-credits'>
        <p>Image credits</p>
        <ul>
        <li><a href="https://www.flaticon.com/free-icons/f1" title="f1 icons">F1 icons created by Moon.de - Flaticon</a></li>
        <li><a href="https://www.flaticon.com/free-icons/watch" title="watch icons">Watch icons created by Freepik - Flaticon</a></li>
        <li><a href="https://www.flaticon.com/free-icons/ai" title="ai icons">Ai icons created by photo3idea_studio - Flaticon</a></li>
        </ul>
      </div>
     <p className='ajs'>&copy;ZlatanDev All Rights Reserved</p>
    </section>
    
    </>
  );
}


export default App;
