import React, { useState, useEffect } from 'react';

const ZlatanComponent = () => {
    const phrases = [
        'Full stack developer',
        'Meta ads specialist',
        'Content creator',
        'UX and UI designer',
        
    ];

    const [text, setText] = useState(phrases[0]);
    const [index, setIndex] = useState(0);
    const [resetAnimation, setResetAnimation] = useState(false);

    useEffect(() => {
        if (resetAnimation) {
            setIndex(0);
            setResetAnimation(false);
            return;
        }

        const timeout = setTimeout(() => {
            const nextIndex = (index + 1) % phrases.length;
            setText(phrases[nextIndex]);
            setIndex(nextIndex);
        }, 3000); 

        return () => clearTimeout(timeout);
    }, [index, resetAnimation]);

    useEffect(() => {
        if (!resetAnimation) {
            const interval = setInterval(() => {
                setText((prevText) => {
                    if (prevText.length > 0) {
                        return prevText.slice(0, -1);
                    } else {
                        clearInterval(interval);
                        return phrases[index];
                    }
                });
            }, 200); 
            return () => clearInterval(interval);
        }
    }, [index, resetAnimation, phrases]);

    useEffect(() => {
        
        if (index === phrases.length - 1) {
            const resetTimeout = setTimeout(() => {
                setResetAnimation(true);
            }, 5000); 
            return () => clearTimeout(resetTimeout);
        }
    }, [index, phrases]);

    return (
        <div style={{ fontFamily: 'monospace', fontSize: '24px', color: 'white' }}>
            {text}
        </div>
    );
};

export default ZlatanComponent;
